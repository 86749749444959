import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utlis/rem'
import moment from 'moment'

// import 'u-reset.css'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/ureset.css';
import '@/assets/css/common.less';
import '@/assets/css/com.css';
import '@/assets/css/iconfont.css';

import Vcharts from 'v-charts';
// import './utlis/rem.js';
import api from "./api";
import 'es6-promise/auto';
import filters from "./plugins/filters";
// import Print from 'vue-print-nb'
// Vue.use(Print)
Vue._watchers = Vue.prototype._watchers = []
Vue.use(ElementUI);
Vue.use(filters);
Vue.use(Vcharts);
Vue.config.productionTip = false
import bus from '@/utlis/bus.js'
Vue.prototype.$bus = bus
Vue.prototype.$moment = moment
Vue.prototype.api = api;
new Vue({
	router,
	store,
  render: h => h(App),
}).$mount('#app')
