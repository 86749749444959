import path from "./path"
import axios from "./axios"

export default {
	homeList(data) {
	    return axios.post(path.homeList, data);
	},
	kcList(data) {
	    return axios.post(path.kcList, data)
	},
    homeKyw(data) { 
        return axios.post(path.homeKyw, data);
    },
    allkc(data) {
        return axios.post(path.allkc, data)
    },
	testKcList(data) {
	    return axios.post(path.testKcList, data);
	},
	continueClass(data) {
	    return axios.post(path.continueClass, data);
	},
	testSubmit(data) {
	    return axios.post(path.testSubmit, data)
	},
	wordList(data) {
	    return axios.post(path.wordList, data)
	},
	startSk(data) {
	    return axios.post(path.startSk, data)
	},
	review(data) {
	    return axios.post(path.review, data)
	},
	reviewSubmit(data) {
	    return axios.post(path.reviewSubmit, data)
	},
	classTest(data) {
	    return axios.post(path.classTest, data)
	},
	testKqSubmit(data) {
	    return axios.post(path.testKqSubmit, data)
	},
	getWords(data) {
	    return axios.post(path.getWords, data)
	},
	jianList(data) {
	    return axios.post(path.jianList, data)
	},
	xhList(data) {
	    return axios.post(path.xhList, data)
	},
	xhSubmit(data) {
	    return axios.post(path.xhSubmit, data)
	},
	createKyw(data) {
	    return axios.post(path.createKyw, data)
	},
	editKyw(data) {
	    return axios.post(path.editKyw, data)
	},
	delKyw(data) {
	    return axios.post(path.delKyw, data)
	},
	printKyw(data) {
	    return axios.post(path.printKyw, data)
	},
	kywList(data) {
	    return axios.post(path.kywList, data)
	},
	xkList(data) {
	    return axios.post(path.xkList, data)
	},
	studentInfo(data) {
	    return axios.post(path.studentInfo, data)
	},
	studentDetail(data){
	    return axios.post(path.studentDetail, data)
	},
	myInfo(data) {
	    return axios.post(path.myInfo, data)
	},
	editInfo(data){
	    return axios.post(path.editInfo, data)
	},
	testRecord(data){
	    return axios.post(path.testRecord, data)
	},
	finishClass(data){
	    return axios.post(path.finishClass, data)
	},
}